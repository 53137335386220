import {Button, Container, Title} from "@mantine/core";
import {HeaderTabs} from "../../Layout/HeaderTabs";
import {Link} from "react-router-dom";

const NotFoundPage = () => {
  return (
    <>
      <HeaderTabs/>
      <Container size="md" ta="center">
        <Title order={1} mt="5rem">Stránka nebyla nalezena</Title>
        <Link to="/">
          <Button mt="xl" variant="default">Zpět na hlavní stránku</Button>
        </Link>
      </Container>

    </>
  )
}

export default NotFoundPage
