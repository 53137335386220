const PXLRegex = /PXL_([0-9]{4})([0-9]{2})([0-9]{2})_([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{3}).[a-zA-Z]*/
const SamsungRegex = /([0-9]{4})([0-9]{2})([0-9]{2})_([0-9]{2})([0-9]{2})([0-9]{2}).[a-zA-Z]*/
const DJIRegex = /DJI_([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})_.*/
const DJIFlyRegex = /dji_fly_([0-9]{4})([0-9]{2})([0-9]{2})_([0-9]{2})([0-9]{2})([0-9]{2})_.*/

function fileToDate(file: File) {
    try {
        //TODO: pxl format use UTC time, (+2 hours adding)
        if(file.name.match(PXLRegex)) {
            const match = file.name.match(PXLRegex)
            //@ts-ignore
            //return new Date(`${match[1]}-${match[2]}-${match[3]}T${match[4]}:${match[5]}:${match[6]}.000Z`).getTime()
        }

        if(file.name.match(SamsungRegex)) {
            const match = file.name.match(SamsungRegex)
            //@ts-ignore
            //return new Date(`${match[1]}-${match[2]}-${match[3]}T${match[4]}:${match[5]}:${match[6]}.000Z`).getTime()
        }

        if(file.name.match(DJIRegex)) {
            const match = file.name.match(DJIRegex)
            //@ts-ignore

            const date = new Date(`${match[1]}-${match[2]}-${match[3]}T${match[4]}:${match[5]}:${match[6]}+0200`).getTime()

            console.log(date)
        }

        if(file.name.match(DJIFlyRegex)) {
            const match = file.name.match(DJIFlyRegex)
            //@ts-ignore
            const date = new Date(`${match[1]}-${match[2]}-${match[3]}T${match[4]}:${match[5]}:${match[6]}+0200`).getTime()
            console.log("fly",date)
        }

    } catch (e) {
        console.error("FILE-TO-DATE", e)
        return file.lastModified
    }


    return file.lastModified
}

export default fileToDate
