import {UnstyledButton, Text, Card, Button, Group, Box, Title} from "@mantine/core";
import classes from './FeatureCard.module.css';
import React from "react";
import {IconArrowRight} from "@tabler/icons-react";

interface Props {
  title?: string
  desc?: string
  href?: string
  color: string
  btnColor: string
}

const FeatureCard = (props: Props) => {

  return (
    <Box h="9rem" w="15rem" bg={props.color} p="1rem" style={{borderRadius: "0.5rem"}}>
      <Title order={3} c="white">{props.title}</Title>
      <Text mb="md" c="white">{props.desc}</Text>
      {
        props.href ? (
          <a href={props.href}>
            <Button color={props.btnColor} rightSection={<IconArrowRight size={14} />}>Otevřít</Button>
          </a>
          ) : (
          <Button disabled color={props.btnColor}>Nedostupné</Button>
        )}
    </Box>
  )

  /*
  return (
    <Card withBorder padding="lg" radius="md" w={300}>
      <Text fz="lg" className={classes.title} fw={500}>

      </Text>
      <Text fz="xs" c="dimmed" mt={3} mb="xl">
        {props.desc}
      </Text>


      <Group>
        {
          props.href ? (
            <a href={props.href}>
              <Button>Otevřít</Button>
            </a>
          ) : <Button disabled>Nedostupné</Button>
        }
      </Group>
    </Card>
  )

   */
}

export default FeatureCard
