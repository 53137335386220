import useAuth from "../../../../utils/auth";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";

const useUpdateAppDataRow = (id: string, row: string) => {
  const auth = useAuth()

  const client = useQueryClient()

  return useMutation({
    mutationKey: ['apps-data-row', id, row],
    mutationFn: (arg: {rowData: any}) => axios.put(`${config.gDriveApi}/apps/${id}/row/${row}`, arg.rowData, {headers: {'Authorization': `Bearer ${auth.apiKey}`}}),
    onSuccess: () => {
      client.invalidateQueries({queryKey: ['apps-data-row', id, row]})
      client.invalidateQueries({queryKey: ['apps-data', id]})
    }
  })
}

export default useUpdateAppDataRow
