import useConfig from "./hooks/useConfig";
import {Link, useParams} from "react-router-dom";
import {HeaderTabs} from "../../Layout/HeaderTabs";
import React, {useMemo, useState} from "react";
import {Anchor, Breadcrumbs, Container, Title, Text, Table, Button, TextInput} from "@mantine/core";
import PageHeader from "../../Shared/PageHeader";
import useAppList, {AppRow} from "./hooks/useData";
import {UiText} from "./types/config";
import {IconAlertTriangle, IconCheck, IconFileDescription} from "@tabler/icons-react";
import EmptyState from "../../Shared/EmptyState";
import Fuse from "fuse.js";
import {getText, isDone} from "./utils";


const AppList = () => {
  const {id} = useParams()
  const configQuery = useConfig(id || "")
  const dataQuery = useAppList(id || "")

  const config = configQuery?.data
  const dataFromQuery = dataQuery?.data

  const [query, setQuery] = useState("")

  const fuse = useMemo(() => {
    if (!config || !dataFromQuery) return null

    return new Fuse(dataFromQuery, {
      keys: config.ui.search.keys
    })
  }, [dataFromQuery, config])


  const data = query ? fuse?.search(query)?.map(item => item.item) : dataFromQuery

  return (
    <>
      <HeaderTabs/>
      <Container size="md">
        <Breadcrumbs mb={10}>
          <Link to="/"><Anchor>Dashboard</Anchor></Link>
          <Link to={`/drive-apps/${id}`}><Anchor>{config?.ui?.listing?.page_title}</Anchor></Link>
        </Breadcrumbs>

        <PageHeader
          title={config?.ui?.listing?.page_title || ""}
          subTitle={config?.ui?.listing?.page_subtitle}
        />

        <TextInput label="Vyhledávání" placeholder="Jméno/..." mb="md" onChange={(e) => setQuery(e.target.value)}/>

        <Table striped>
          <Table.Tbody>
            {
              data?.map(item => {
                const done = isDone(config ? config.ui.listing.done : "", item)
                const warning = isDone(config ? config.ui.listing.warning : "", item)

                return (
                  <Table.Tr key={item.row_number}
                            bg={warning && done ? "#fff0d0" : (warning ? "#ffd0d0" : (done ? "#cdedd3" : ""))}>
                    <Table.Td width="10rem">
                      {done ? <IconCheck size={24} color="green"/> : null}
                      {warning ? <IconAlertTriangle size={24} color="orange"/> : null}
                    </Table.Td>
                    <Table.Td>
                      <Title order={4}>{getText(config ? config.ui.listing.row_title : null, item)}</Title>
                      <Text>{getText(config ? config.ui.listing.row_subtitle : null, item)}</Text>

                    </Table.Td>
                    <Table.Td ta="right">
                      <Link to={`/drive-apps/${id}/${item.row_number}`}>
                        <Button leftSection={<IconFileDescription size={14}/>} variant="default">Zobrazit</Button>
                      </Link>
                    </Table.Td>
                  </Table.Tr>
                )
              })
            }
          </Table.Tbody>
        </Table>

        <EmptyState isEmpty={!data?.length} isLoading={dataQuery.isLoading}/>
      </Container>
    </>
  )
}

export default AppList
