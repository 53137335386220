import {HeaderTabs} from "../../../Layout/HeaderTabs";
import React, {useRef, useState} from "react";
import {
  ActionIcon,
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Container, Flex, Loader,
  Modal,
  Table,
  Text,
  TextInput,
  Title
} from "@mantine/core";
import {Link} from "react-router-dom";
import {IconEdit, IconTrash, IconUpload} from "@tabler/icons-react";
import useLocalStorage from "use-local-storage";
import {useDisclosure} from "@mantine/hooks";
import usePosters, {defaultPoster, useDeleteSavePoster, useSavePoster} from "./usePosters";
import {notifications} from "@mantine/notifications";
import EmptyState from "../../../Shared/EmptyState";
import PageHeader from "../../../Shared/PageHeader";

const EventsPostersGeneratorPage = () => {
  const [items, setItems] = useLocalStorage<{ id: string, name: string }[]>(`list-event-poster`, [])

  const [opened, {open, close}] = useDisclosure(false);

  const newNameRef = useRef<any>()

  const posters = usePosters()
  const savePoster = useSavePoster()
  const deletePoster = useDeleteSavePoster()


  const [savePosterLoading, setSavePosterLoading] = useState(false)

  const addNew = () => {
    if (!newNameRef.current.value) return

    setSavePosterLoading(true)

    /*setItems([...items, {
      id: new Date().getTime().toString(),
      name: newNameRef.current.value
    }])*/

    savePoster({
      ...defaultPoster,
      name: newNameRef.current.value ?? defaultPoster.name
    }).then(() => {
      posters.refetch()
      close()
      setSavePosterLoading(false)
    }).catch(() => {
      notifications.show({
        title: 'Něco se pokazilo',
        message: '',
      })
      setSavePosterLoading(false)
    })

    newNameRef.current.value = ""
  }

  const deleteApi = (id: number) => {
    if (global.confirm("Doopravy chceš smazat plakátek?")) {
      deletePoster(id).then(() => {
        posters.refetch()
      })
    }
  }

  const removeLocal = (id: string | number) => {
    if (global.confirm("Doopravy chceš smazat plakátek?")) {
      setItems([...items].filter(item => item.id !== id))
    }
  }

  const upload = (poster: any) => {
    savePoster({...defaultPoster, ...poster, id: null}).then(() => {
      setItems([...items].filter(item => item.id !== poster.id))
      posters.refetch()
    })
  }

  return (
    <div>
      <HeaderTabs/>

      <Container size="md">
        <Breadcrumbs mb={10}>
          <Link to="/"><Anchor>Dashboard</Anchor></Link>
          <Link to="/generator/events"><Anchor>Generátor plakátů</Anchor></Link>
        </Breadcrumbs>

        <PageHeader title="Generátor plakátů" btn={<Button mb="md" color="green" onClick={open}>+ Nový plakátek</Button>}/>

        <Modal opened={opened} onClose={close} title="Nový plakátek">
          <TextInput label="Název" required ref={newNameRef} mb="md"/>


          {
            savePosterLoading ? (
              <Loader color="green" size="xs"/>
            ) : (
              <Button mb="md" color="green" onClick={addNew}>Vytvořit</Button>
            )
          }
        </Modal>


        <Table striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Název</Table.Th>
              <Table.Th>ID</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {
              posters.data?.map((item, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    {item.name}
                  </Table.Td>
                  <Table.Td>
                    {item.id}
                  </Table.Td>
                  <Table.Td ta="right">
                    <ActionIcon variant="light" color="red" mr="xs"><IconTrash style={{height: "70%"}}
                                                                               onClick={() => item.id ? deleteApi(item.id) : null}/></ActionIcon>
                    <Link to={`/generator/events/${item.id}`}>
                      <ActionIcon><IconEdit style={{height: "70%"}}/></ActionIcon>
                    </Link>
                  </Table.Td>
                </Table.Tr>
              ))
            }

            {
              (items || []).map((item, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    {item.name}
                  </Table.Td>
                  <Table.Td>
                    local:{item.id}
                  </Table.Td>
                  <Table.Td ta="right">
                    <Text color="red">Pro editaci je potřeba převést plakátek do online verze!</Text>
                    <ActionIcon variant="light" color="red" mr="xs"><IconTrash style={{height: "70%"}}
                                                                               onClick={() => removeLocal(item.id)}/></ActionIcon>
                    <ActionIcon disabled mr="xs"><IconEdit style={{height: "70%"}}/></ActionIcon>
                    <ActionIcon color="green" onClick={() => upload(item)}><IconUpload
                      style={{height: "70%"}}/></ActionIcon>
                  </Table.Td>
                </Table.Tr>
              ))
            }
          </Table.Tbody>
        </Table>

        <EmptyState isEmpty={!posters.data?.length} isLoading={posters.isLoading}/>
      </Container>
    </div>
  )
}

export default EventsPostersGeneratorPage
