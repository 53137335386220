import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./app.css"

import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';

import "./fonts.css"

import {createTheme, MantineProvider} from '@mantine/core';
import {Notifications} from "@mantine/notifications";

const theme = createTheme({
  /** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <Notifications/>
      <App/>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
