import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";
import useAuth from "../../../../utils/auth";
import {useMemo} from "react";

export interface Gallery {
  "id": number
  "title": string
  "date": string
  "slug": string
  "upper_gallery": number,
  "image": string
  "required_roles": string,
  "article": string,
  "folder": string
  "hide_date":  boolean
  hide_from_users: boolean
  sub_galleries?: Gallery[]
}


const useGalleries = (year: number|null) => {
  const auth = useAuth()

  const galleries = useQuery({
    queryKey: ['galleries', year],
    queryFn: () =>
      axios
        .get<Gallery[]>(`${config.apiURL}/api/galleries/years/${year}`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })

  return {
    ...galleries
  }
}

export default useGalleries
