import AuthLayout from "../Layout/Auth";
import {Loader, Text, Title} from "@mantine/core";
import React, {useEffect, useRef} from "react";
import useSearchParamKey from "../../utils/useSearchParamKey";
import useLocalStorage from "use-local-storage";
import {User} from "../../utils/auth";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import config from "../../config";

const Auth = () => {
  const token = useSearchParamKey("token")

  const [apiKey, setApiKey] = useLocalStorage<string | null>("api-key", "")
  const [apiUser, setApiUser] = useLocalStorage<User | null>("api-user", null)

  const navigate = useNavigate()

  const tokenCheck = useRef(false)

  useEffect(() => {
    window.addEventListener('DOMContentLoaded', () => {
      let displayMode = 'browser tab';

      if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone';
      }

      // Log launch display mode to analytics
      console.log('DISPLAY_MODE_LAUNCH:', displayMode);
    });
  }, []);

  useEffect(() => {
    if (!token || tokenCheck.current) return

    tokenCheck.current = true

    axios.post(config.apiURL + "/auth/token", {jwt: token}).then((res) => {
      if(!res.data.roles.includes("ADMIN")) {
        navigate("/auth?error=roles")
        return
      }

      setApiKey(res.data.token)
      setApiUser(res.data.user)

      //@ts-ignore
      umami.track("login", {
        ...res.data.user
      })

      setTimeout(() => {
        if(window.matchMedia('(display-mode: standalone)').matches) {
          navigate("/")
        } else {
          navigate("/auth/download-app")
        }
      }, 1000)
    }).catch(() => {
      navigate("/auth?error=true")
    })
  }, [token])

  return (
    <AuthLayout>
      <Title ta="center">
        Načítám...
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Kontroluje se přihlášení...
      </Text>

    </AuthLayout>
  )
}

export default Auth
