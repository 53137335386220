// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail_photoCard__VxCSW {
    width: 25%;
}


.detail_photoWrapper__oBZ30 {
    position: relative;
}

.detail_absolute__NSoDj {
    padding: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Galeries/Detail/detail.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".photoCard {\n    width: 25%;\n}\n\n\n.photoWrapper {\n    position: relative;\n}\n\n.absolute {\n    padding: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photoCard": `detail_photoCard__VxCSW`,
	"photoWrapper": `detail_photoWrapper__oBZ30`,
	"absolute": `detail_absolute__NSoDj`
};
export default ___CSS_LOADER_EXPORT___;
