import {Link, useParams} from "react-router-dom";
import {HeaderTabs} from "../../Layout/HeaderTabs";
import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container, Flex,
  NumberInput,
  Switch,
  Table,
  TextInput
} from "@mantine/core";
import PageHeader from "../../Shared/PageHeader";
import React, {useRef, useState} from "react";
import useConfig from "./hooks/useConfig";
import useAppDataRow from "./hooks/useAppDataRow";
import EmptyState from "../../Shared/EmptyState";
import {getAllInputs, getText} from "./utils";
import useUpdateAppDataRow from "./hooks/useUpdateAppDataRow";
import {IconCheck, IconX} from "@tabler/icons-react";

const RowDetail = () => {
  const {id, row: rowNum} = useParams()

  const configQuery = useConfig(id || "")
  const rowQuery = useAppDataRow(id || "", rowNum || "")

  const config = configQuery?.data
  const row = rowQuery?.data

  const title = row ? getText(config ? config.ui.listing.row_title : null, row) : ""

  const formRef = useRef<any>()

  const updateRow = useUpdateAppDataRow(id || "", rowNum || "")

  const [edit, setEdit] = useState(false)

  const handleSave = () => {
    const data = getAllInputs(formRef.current)
    updateRow.mutateAsync({rowData: data}).then(() => {
      setEdit(false)
    })
  }

  return (
    <>
      <HeaderTabs/>
      <Container size="md">
        <Breadcrumbs mb={10}>
          <Link to="/"><Anchor>Dashboard</Anchor></Link>
          <Link to={`/drive-apps/${id}`}><Anchor>{config?.ui?.listing?.page_title}</Anchor></Link>
          <Link to={`/drive-apps/${id}/${rowNum}`}><Anchor>{title}</Anchor></Link>
        </Breadcrumbs>

        <PageHeader
          title={title}
          subTitle={config?.ui?.listing?.page_title}
          btn={
            !edit ? (
              <Button onClick={() => setEdit(true)} loading={updateRow.isPending}>Edit</Button>
            ) : (
              <div>
                <Button variant="default" mr="xs" onClick={() => setEdit(false)}>Zrušit změny</Button>
                <Button color="green" onClick={handleSave} loading={updateRow.isPending}>Uložit</Button>
              </div>
            )
          }
        />

        {
          !edit ? (
            <Table>
              {
                config ? Object.values(config?.row?.columns).map((col, key) => (
                  <Table.Tr>
                    <Table.Td>{col.ui.label}</Table.Td>
                    <Table.Td>
                      {
                        !row ? (
                          ""
                        ) : col.ui.special === "tel" ? (
                          <a href={`tel://${row.data[col.name]}`}>{row.data[col.name]}</a>
                        ) : col.type === "boolean" ? (
                          row.data[col.name] ? <Flex align="center" gap="xs"><IconCheck/> Ano</Flex> : <Flex align="center" gap="xs"><IconX/> Ne</Flex>
                        )  : (
                          row.data[col.name]
                        )
                      }
                    </Table.Td>
                  </Table.Tr>
                )) : null
              }
            </Table>
          ) : (
            <div ref={formRef}>
              {
                config ? Object.values(config?.row?.columns).map((col, key) => (
                  <Box mb="md">
                    {row ? (
                      col.type === "int" ? (
                        <NumberInput name={col.name} label={col.ui.label} defaultValue={row.data[col.name]}
                                     disabled={!col.ui.editable}
                                     step={1}/>
                      ) : col.type === "float" ? (
                        <NumberInput name={col.name} label={col.ui.label} defaultValue={row.data[col.name]}
                                     disabled={!col.ui.editable}
                                     step={0.1}/>
                      ) : col.type === "boolean" ? (
                        <Box mt="xl" mb="xl">
                          <Checkbox name={col.name} defaultChecked={!!row.data[col.name]} label={col.ui.label}
                                    disabled={!col.ui.editable}/>
                        </Box>
                      ) : (
                        <TextInput name={col.name} label={col.ui.label} defaultValue={row.data[col.name]}
                                   disabled={!col.ui.editable}/>
                      )
                    ) : null}
                  </Box>
                )) : null
              }
            </div>
          )
        }


        <EmptyState isEmpty={!row} isLoading={rowQuery.isLoading}/>

      </Container>
    </>
  )
}

export default RowDetail
