import {RichTextEditor, Link} from '@mantine/tiptap';
import {useEditor} from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import {Textarea} from "@mantine/core";
import {useRef} from "react";


interface Props {
  defaultValue: string
  name: string
  onUpdate?: (value: string) => void
  onlyTextarea?: boolean
}

const RitchText = (props: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>()

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({types: ['heading', 'paragraph']}),
    ],
    content: props.defaultValue,
    onUpdate: (up) => {
      if(textareaRef.current) textareaRef.current.value = up.editor.getHTML()
      if(props.onUpdate) props.onUpdate(up.editor.getHTML())
    },
    onCreate: (up) => {
      if(textareaRef.current) textareaRef.current.value = up.editor.getHTML()
    }
  });

  return (
    <>
      <RichTextEditor editor={editor}>
        {!props.onlyTextarea ? (
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold/>
              <RichTextEditor.Italic/>
              <RichTextEditor.Underline/>
              <RichTextEditor.Strikethrough/>
              <RichTextEditor.ClearFormatting/>
              <RichTextEditor.Highlight/>
              <RichTextEditor.Code/>
            </RichTextEditor.ControlsGroup>


            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1/>
              <RichTextEditor.H2/>
              <RichTextEditor.H3/>
              <RichTextEditor.H4/>
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote/>
              <RichTextEditor.Hr/>
              <RichTextEditor.BulletList/>
              <RichTextEditor.OrderedList/>
              <RichTextEditor.Subscript/>
              <RichTextEditor.Superscript/>
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link/>
              <RichTextEditor.Unlink/>
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft/>
              <RichTextEditor.AlignCenter/>
              <RichTextEditor.AlignJustify/>
              <RichTextEditor.AlignRight/>
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Undo/>
              <RichTextEditor.Redo/>
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
        ) : null}



        <RichTextEditor.Content mih="15rem"/>
      </RichTextEditor>

      {/* @ts-ignore */}
      <textarea style={{display: "none"}} name={props.name} ref={textareaRef}/>
    </>
  );
}

export default RitchText
