import {HeaderTabs} from "../../../Layout/HeaderTabs";
import {Anchor, Badge, Box, Breadcrumbs, Button, Container, Flex, Text, Title} from "@mantine/core";
import {Link, useParams} from "react-router-dom";
import React from "react";
import useGallery from "../hooks/useGallery";
import Upload from "./Upload";

const GalleryUpload = () => {
  const params = useParams()
  const id = params.id ? parseInt(params.id) : null

  const {gallery} = useGallery(id)
  const {gallery: upGallery} = useGallery(gallery?.upper_gallery ?? null)


  return (
    <>
      <HeaderTabs/>
      <Container size="md" mb={20}>
        <Breadcrumbs mb={10}>
          <Link to="/"><Anchor>Dashboard</Anchor></Link>
          <Link to="/galeries"><Anchor>Galerie</Anchor></Link>
          {upGallery ? <Link
            to={`/galeries/${upGallery?.id}`}><Anchor>{upGallery?.title} ({upGallery?.date})</Anchor></Link> : null}
          <Link to={`/galeries/${gallery?.id}`}><Anchor>{gallery?.title} ({gallery?.date})</Anchor></Link>
        </Breadcrumbs>

        <Flex justify="space-between" align="flex-start" mt="md" >
          <Box>
            <Title order={1} size="1.5rem" fw="bold">{gallery?.title ?? "Galerie"}</Title>
            <Text>{gallery?.date}</Text>
            {
              gallery?.hide_date ? (<Badge color="red">Schovat datum</Badge>) : null
            }
            {
              gallery?.hide_from_users ? (<Badge color="red">ADMIN ONLY</Badge>) : null
            }
          </Box>
          <Link to={`/galeries/${id}`}>
            <Button mb="md" color="gray" variant="light">Zpět na přehled fotek</Button>
          </Link>
        </Flex>


        <Upload galleryID={params.id || ""}/>
      </Container>
    </>
  )
}

export default GalleryUpload
