const f2 = (num: number) => num >= 10 ? num : `0${num}`

export const timeToString = (time: string | number) => {
  //@ts-ignore
  const date = new Date(parseInt(time))
  return dateToString(date)
}

export const dateToString = (date: Date) => {
  return `${date.getDate()}. ${date.getMonth()+1}. ${date.getFullYear()} ${f2(date.getHours())}:${f2(date.getMinutes())}`
}
