import AuthLayout from "../Layout/Auth";
import config from "../../config";
import {Alert, Button, Text, Title} from "@mantine/core";
import React from "react";
import useSearchParamKey from "../../utils/useSearchParamKey";
import {IconInfoCircle} from "@tabler/icons-react";

const authURL = `${config.apiURL}/auth/external-app?redirect=${encodeURIComponent(config.appURL+"/auth/token")}`
const Auth = () => {
  const error = useSearchParamKey("error")

  return (
    <AuthLayout>
      {
        error && error !== "roles" ? (
          <Alert variant="light" color="red" title="Vyskytla se chyba" icon={<IconInfoCircle />} mb={30}>
            Nepovedlo se ověřit tvoje příhlášení na skautském webu, zkus to znovu.
          </Alert>
        ) : null
      }

      {
        error === "roles" ? (
          <Alert variant="light" color="orange" title="Nedostatečná oprávnění" icon={<IconInfoCircle />} mb={30}>
            Tvůj účet nemá dostatečná práva pro tuto aplikaci.
          </Alert>
        ) : null
      }

      <Title ta="center">
        Vítej zpět!
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Přihlaš se do vedoucovské aplikace.
      </Text>

      <a href={authURL}>
        <Button fullWidth mt="xl" color="green">
          Přihlásit se přes skautský web (SSO)
        </Button>
      </a>
    </AuthLayout>
  )
}

export default Auth
