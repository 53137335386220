import AuthLayout from "../../Layout/Auth";
import {Box, Button, Text, Title} from "@mantine/core";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTimeout} from "@mantine/hooks";

const DownloadApp = () => {
  const navigate = useNavigate()
  const installPrompt = useRef<any>()

  const [disabled, setDisabled] = useState(false)

  const timeout = useTimeout(() => {
    if (!installPrompt.current) {
      navigate("/")
    }
  }, 1000)

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      navigate("/")
    }

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      installPrompt.current = event;
      setDisabled(false)
    });

    timeout.start()

    return timeout.clear()
  }, []);

  const handleDownload = () => {
    if (installPrompt.current) installPrompt.current.prompt();
  }

  return (
    <AuthLayout>
      <Title ta="center">
        Nainstaluj si apku!
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Budeš mít vše hned poruce a nemusíš se pokaždé přihlašovat.
      </Text>

      <Box mt="xl">
        <Button color={"green"} fullWidth onClick={disabled ? () => {
        } : handleDownload} disabled={disabled}>Stáhnout appku</Button>
        <a href="/">
          <Button variant="light" mt="xs" fullWidth>Pokračovat bez aplikace</Button>
        </a>
      </Box>

    </AuthLayout>
  )
}

export default DownloadApp
