import useGalleryPhotos, {Photo} from "../hooks/useGalleryPhotos";
import classes from "./detail.module.css";
import {Button, Card, Group, Image, Loader, Modal, rem} from "@mantine/core";
import config from "../../../../config";
import {IconKey, IconLock, IconTrash} from "@tabler/icons-react";
import React from "react";
import {useDisclosure} from "@mantine/hooks";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import useAuth from "../../../../utils/auth";
import {useQueryClient} from '@tanstack/react-query'

interface Props {
  galleryId: number | null
  slug: string
  photo?: Photo
}

const PhotoEl = ({photo, slug, galleryId}: Props) => {
  const [opened, {open, close}] = useDisclosure(false);
  const auth = useAuth()
  const photos = useGalleryPhotos(galleryId)

  const queryClient = useQueryClient()

  const deleteMutation = useMutation({
    mutationFn: () => {
      return axios.delete(`${config.apiURL}/api/photos/${photo?.id}`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
    },
    onSuccess: (_, variables) => {
      close()

      const data: any = queryClient.getQueryData(['gallery', galleryId])

      if (data) {
        const copy = JSON.parse(JSON.stringify(data))
        copy.photos = copy.photos.filter((i: Photo) => i.id !== photo?.id)

        queryClient.setQueryData(['gallery', galleryId], copy)
      }

      photos.refetch()
    }
  })

  const lockMutation = useMutation({
    mutationFn: (lock: boolean) => {
      return axios.put(`${config.apiURL}/api/photos/${photo?.id}/${lock ? "lock" : "unlock"}`, null, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
    },
    onSuccess: (_, variables, context) => {
      const data: any = queryClient.getQueryData(['gallery', galleryId])

      if (data) {
        const copy = JSON.parse(JSON.stringify(data))
        const item = copy.photos.find((i: Photo) => i.id === photo?.id)
        item.locked = variables

        queryClient.setQueryData(['gallery', galleryId], copy)
      }
    }
  })

  return (
    <>
      <Group justify="right" gap={5}>
        {
          photo?.locked && photo?.locked > 0 ? (
            <Button variant="light" color="red" size="xs" onClick={() => lockMutation.mutate(false)}>
              {lockMutation.isPending ? <Loader color="red" size="xs"/> : <IconLock style={{height: rem(18)}}/>}
            </Button>
          ) : (
            <Button variant="light" color="green" size="xs" onClick={() => lockMutation.mutate(true)}>
              {lockMutation.isPending ? <Loader color="green" size="xs"/> : (
                <>
                  <IconKey style={{height: rem(18)}}/>Odemknuto
                </>
              )}
            </Button>
          )
        }
        <Button variant="filled" color="red" size="xs" onClick={open}><IconTrash style={{height: rem(18)}}/></Button>
      </Group>


      <Modal opened={opened} onClose={close} title="Do you want remove this photo?">
        <Image
          src={`${config.apiURL}/photos/${slug}/${photo?.path}/600`}
          height={300}
          style={{objectFit: "contain", backgroundColor: "#dee2e6"}}
          mb={10}
        />

        <Group justify="right" gap={5}>
          {
            deleteMutation.isPending ? (
              <Loader color="red" size="xs"/>
            ) : (
              <>
                <Button variant="filled" color="red" onClick={() => deleteMutation.mutate()}>Smazat</Button>
                <Button variant="light" color="red" onClick={close}>Ponechat</Button>
              </>
            )
          }
        </Group>
      </Modal>
    </>
  )
}

export default PhotoEl
