import React from "react";
import styles from "./Auth.module.css";
import {Container, Paper} from "@mantine/core";
import config from "../../config";

interface Props {
  children?: React.ReactNode
}

const AuthLayout = (props: Props) => {
  return (
    <Container size={420} my={40}>
      <div className={styles.logo}>
        <img src="https://www.skautstezery.cz/img/logo.svg" alt=""/>
      </div>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {props.children}
      </Paper>
    </Container>
  )
}

export default AuthLayout
