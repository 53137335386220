import {useRef} from "react";

const useMDebounce = (fnc: any, timeout: number) => {
  const timeoutRef = useRef()

  return (...args: any[]) => {
    clearTimeout(timeoutRef.current)

    // @ts-ignore
    timeoutRef.current = setTimeout(() => {
      console.log(fnc, args)
      fnc(...args)
    }, timeout)

  }

}

export default useMDebounce
