const processAxiosError = (error: any) => {

  if(!error) return null

  if(error?.response?.data?.errors) {
    return {
      title: "Něco se pokazilo ",
      text:  Object.entries(error.response.data.errors).map(([key, value]) => `${key}: ${value}`).join(", \n"),
      data: error.response.data.errors
    }
  }


  return {
    title: "Něco se pokazilo :( ",
    text:  error.message
  }
}

export default processAxiosError
