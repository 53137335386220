import {Gallery} from "../hooks/useGalleries";
import {ActionIcon, Badge, Box, Button, Card, Group, Image, Text} from "@mantine/core";
import config from "../../../../config";
import {Link} from "react-router-dom";
import {IconFolder, IconPencil} from "@tabler/icons-react";
import React from "react";

interface Props {
  gallery: Gallery
}

const GalleryCard = ({gallery} : Props) => {
  //const galleries = useGalleries()

  //const subs = galleries.getSubGalleries(gallery.id)

  return (
    <Card shadow="sm" padding="lg" radius="md" w={210} withBorder key={gallery.id}>
      <Card.Section h={160} bg="#dee2e6">
        {
          gallery.image ? (
            <Image
              src={`${config.apiURL}/gallery-preview/${gallery.image.replace("/uploads/galleries/", "galleries-")}`}
              height={160}
            />
          ) : null
        }
      </Card.Section>

      <Box h={100}>
        <Text fw={500} mt="md">{gallery.title}</Text>
        <Text size="sm" c="dimmed">{gallery.date}</Text>

        {/*
          subs?.length ? (
            <Badge color="green">{subs?.length} podgalerií</Badge>) : null
        */}
        {
          gallery.hide_date ? (<Badge color="red">Schovat datum</Badge>) : null
        }
        {
          gallery.hide_from_users ? (<Badge color="red">ADMIN ONLY</Badge>) : null
        }
      </Box>

      <Group justify="right" gap={4}>
        {/*<ActionIcon color="blue" mt="md" radius="md" variant="light" size="lg" p={5}><IconPencil/></ActionIcon>*/}
        <Link to={`/galeries/${gallery.id}`}>
          <Button color="blue" fullWidth mt="md" radius="md" variant="filled"
                  leftSection={<IconFolder/>}>Otevřít</Button>
        </Link>
      </Group>
    </Card>
  )
}

export default GalleryCard
