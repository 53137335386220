import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";
import useAuth from "../../../../utils/auth";
import {useMemo} from "react";
import {Gallery} from "./useGalleries";


const useGallery = (id: number|null) => {
  const auth = useAuth()

  const gallery = useQuery({
    queryKey: ['galleries-id', id],
    enabled: !!id,
    queryFn: () =>
      axios
        .get<Gallery>(`${config.apiURL}/api/galleries/id/${id}`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })


  return {
    ...gallery,
    gallery: gallery.data
  }
}

export default useGallery
