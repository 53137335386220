import useAuth from "../utils/auth";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";

interface Props {
  children: React.ReactNode
}
const EnsureLogin = (props: Props) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if(!auth.apiKey && !location.pathname.includes("auth")) {
      navigate("/auth")
    }
  }, [auth.apiKey])

  if (!auth.apiKey) return null

  return <>{props.children}</>
}

export default EnsureLogin
