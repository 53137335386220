import {Gallery} from "../hooks/useGalleries";
import {Box, Button, Group, Input, Loader, Select, SimpleGrid, Switch} from "@mantine/core";
import {getInputDateFormat, getPHPDate} from "../../../../utils/date";
import Cropper from "./Cropper";
import RitchText from "../Create/RitchText";
import React, {useRef, useState} from "react";
import slugify from "../../../../utils/slugify";
import {getAllInputs} from "../../GoogleSheets/utils";
import Form from "../../../../utils/Form";

interface Props {
  gallery?: Gallery
  loading?: boolean
  handleSubmit: (values: any) => void
  upperId?: number | null
}

const GalleryForm = (props: Props) => {
  const [slugChanged, setSlugChanged] = useState(false)
  const slugRef = useRef<any>()

  const [canSubmit, setCanSubmit] = useState(props.gallery)

  const handleChange = (values: any) => {
    setCanSubmit(values.date && values.title)

    if (!slugChanged && values.title && values.date && !props.gallery && slugRef.current) {
      const year = (new Date(values.date)).getFullYear()
      if (slugRef.current) slugRef.current.value = slugify(`${year}-${values.title}`, "-")
    }
  }

  const formRef = useRef<any>()

  const handleSubmit = () => {
    const values = getAllInputs(formRef.current)

    props.handleSubmit({
      img_change: null,
      title: values.title,
      //@ts-ignore
      date: getPHPDate(values.date),
      slug: values.slug,
      upper_gallery: props.gallery ? props.gallery?.upper_gallery : props.upperId,
      required_roles: values.required_roles,
      article: values.article,
      hide_date: values.hide_date ? 1 : 0,
      hide_from_users: values.hide_from_users ? 1 : 0,
    })
  }

  return (
    <div ref={formRef}>
      <Form onSubmit={handleSubmit} onChange={handleChange}>
        <SimpleGrid cols={{base: 1, sm: 2}}>
          <Input.Wrapper label="Název" withAsterisk error="" mb={10}>
            <Input placeholder="zadej název galerie" name="title" autoFocus defaultValue={props.gallery?.title}/>
          </Input.Wrapper>

          <Input.Wrapper label="Datum" withAsterisk error="" mb={20}>
            <Input type="date" name="date" defaultValue={props.gallery?.date ?? getInputDateFormat(new Date())}/>
          </Input.Wrapper>


          <Switch
            label="Schovat běžným uživatelům"
            description="Galerie se zobrazí pouze adminům"
            name="hide_from_users"
            mb="md"
            defaultChecked={props.gallery?.hide_from_users}
          />

          <Switch
            label="Neznám přesný datum"
            description="Datum se nebude zobrazovat, ale využije se k řazení galerie."
            name="hide_date"
            mb="md"
            defaultChecked={props.gallery?.hide_date}
          />

          <Input.Wrapper label={`Slug ${slugChanged ? "(manual)" : ""}`} withAsterisk mb={20}>
            <Input type="text" name="slug" ref={slugRef} onChange={() => {
              setSlugChanged(true)
            }} defaultValue={props.gallery?.slug} disabled={!!props.gallery?.slug}/>
          </Input.Wrapper>

          <Select
            label="Kdo si může zobrazit uzamknuté fotografie"
            placeholder="Vyber možnosti přístupu"
            withAsterisk
            defaultValue={props.gallery ? (props.gallery?.required_roles || "") : 'APP.Gallery'}
            data={[{label: "Pouze přihlášení", value: 'APP.Gallery'}, {label: "Všichni", value: ''}]}
            mb={20}
            name="required_roles"
          />
        </SimpleGrid>

        {/*
        <Cropper label="Náhledový obrázek" name="file" aspectRatio={1}/>
        */}

        <Box mb={20}>
          <RitchText name="article" defaultValue={props?.gallery?.article || ""}/>
        </Box>

        <Group justify="right">
          <Button color="green" type="submit" disabled={!canSubmit} loading={props.loading}>{props.gallery ? "Uložit" : "Vytvořit"}</Button>
        </Group>
      </Form>
    </div>
  )
}

export default GalleryForm
