import {HeaderTabs} from "../../../Layout/HeaderTabs";
import React, {useEffect} from "react";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Group,
  Text,
  Image,
  Badge,
  Box, Loader, Alert, Combobox
} from "@mantine/core";
import {Link} from "react-router-dom";
import config from "../../../../config";
import {IconFolder, IconInfoCircle} from "@tabler/icons-react";
import useLocalStorage from "use-local-storage";
import useGalleries from "../hooks/useGalleries";
import GalleryCard from "./GalleryCard";
import CreateModal from "../Create/CreateModal";
import useGalleriesYears from "../hooks/useGalleriesYears";
import Empty = Combobox.Empty;
import EmptyState from "../../../Shared/EmptyState";
import PageHeader from "../../../Shared/PageHeader";

const GalleryList = () => {
  const [selectedYear, setSelectedYear] = useLocalStorage<number | null>("galleries-year", null)
  const galleries = useGalleries(selectedYear)
  const years = useGalleriesYears()

  useEffect(() => {
    if (years?.data?.length && !selectedYear) setSelectedYear(years?.data[0]?.year)
  }, [years]);

  const yearGalleries = galleries.data?.filter(gallery => !gallery.upper_gallery && new Date(gallery.date).getFullYear() === selectedYear)

  return (
    <>
      <HeaderTabs/>
      <Container size="md" mb={20}>
        <Breadcrumbs mb={10}>
          <Link to="/"><Anchor>Dashboard</Anchor></Link>
          <Link to="/galeries"><Anchor>Galerie</Anchor></Link>
        </Breadcrumbs>

        <PageHeader title="Správa galerií" subTitle={`Zobrazují vytvořené galerie za rok ${selectedYear}.`}/>

        <CreateModal/>

        <Group gap={10}>
          {
            years?.data?.map(year => (
              <Button variant={year.year === selectedYear ? "filled" : "outline"} size="xs" color="green"
                      onClick={() => setSelectedYear(year.year)}>{year.year}</Button>
            ))
          }
        </Group>

        {
          galleries.isError ? (
            <Alert variant="light" color="red" title="Chyba" icon={<IconInfoCircle/>}>
              Nepodařilo se načíst data
            </Alert>
          ) : null
        }

        <Group mt={20}>
          {
            yearGalleries?.map((gallery, index) => (
                <GalleryCard gallery={gallery} key={gallery.id}/>
              )
            )
          }
        </Group>

        <EmptyState isEmpty={!galleries.data?.length} isLoading={galleries.isLoading}/>

      </Container>
    </>
  )
}

export default GalleryList
