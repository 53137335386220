import {Photo} from "../hooks/useGalleryPhotos";
import usePhotosDimensions from "./usePhotosDimensions";
import PhotoAlbum from "react-photo-album";
import config from "../../../../config";

import styles from "./detail.module.css"
import cx from "clsx";
import PhotoButtons from "./PhotoButtons";
import {Box, Button} from "@mantine/core";

interface Props {
  photos: Photo[]
  slug: string
  galleryId: number | null
}

const PhotoAlbumView = (props: Props) => {
  const photos = props.photos.map(photo => ({
    width: photo.width || 500,
    height: photo.height || 500,
    src: `${config.apiURL}/photos/${props.slug}/${photo.path}/300`,
    href: photo.id
  }))

  const getPhoto = (href: number) => {
    return props.photos.find(photo => photo.id === href)
  }

  return (
    <Box mt="md" mb="md">
      <PhotoAlbum layout="rows" photos={photos}
                  renderPhoto={({imageProps: {src, alt, style, ...restImageProps}, photo}) => (
                    <div className={styles.photoWrapper} style={style}>
                      <img src={src} alt={alt} style={{height: "100%", width: "100%", backgroundColor: "#dee2e6"}} {...restImageProps}
                           loading="lazy"/>
                      <div className={styles.absolute}>
                        <PhotoButtons galleryId={props.galleryId} slug={props.slug} photo={getPhoto(photo.href)}/>
                      </div>
                    </div>
                  )}
      />
    </Box>
  )
}

export default PhotoAlbumView

/*
   renderContainer={({children, containerProps, containerRef}) => (
                    <div className={styles.photoWrapper} ref={containerRef} {...containerProps}>
                      {children}
                      <div className={styles.absolute}>
                        <Button>ABCD</Button>
                      </div>
                    </div>
                  )}
 */
