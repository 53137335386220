import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";
import useAuth from "../../../../utils/auth";
import {Gallery} from "./useGalleries";


const useGalleriesYears = () => {
  const auth = useAuth()

  const years = useQuery({
    queryKey: ['gallery-years'],
    queryFn: () =>
      axios
        .get<{ year: number, total: number }[]>(`${config.apiURL}/api/galleries/years`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })


  return years
}

export default useGalleriesYears
