import useAuth from "../../../../utils/auth";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";
import {AppConfig} from "../types/config";

const useConfig = (id: string) => {
  const auth = useAuth()

  return useQuery({
    queryKey: ['apps', id],
    enabled: !!id,
    queryFn: () =>
      axios
        .get<AppConfig>(`${config.gDriveApi}/apps/${id}`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })
}

export default useConfig
