import {useEffect, useRef, useState} from "react";
import fileToDate from "../../../../utils/files/fileToDate";
import {useInterval} from "@mantine/hooks";
import { v4 as uuidv4 } from 'uuid';
import heic2any from "heic2any";
import Compressor from "compressorjs"
import axios from "axios";
import config from "../../../../config";
import useAuth from "../../../../utils/auth";

export enum FileStatus {
  "idle" = "idle",
  "heic" = "heic",
  "compression" = "compression",
  "wainingForUpload" = "wainingForUpload",
  "uploading" = "uploading",
  "done" = "done",
  "failed" = "failed"
}


interface FileInQueueState {
  key: string
  date: string
  status: FileStatus
  progress: number
  name: string
  url: string
  compression?: number
  compressionSize?: number
  size: number
}

interface FileInQueue extends FileInQueueState {
  files: {
    original: File
    file: File | Blob
  }
}

const isRunningCompressStatus = [FileStatus.heic, FileStatus.compression, FileStatus.uploading]
const isRunningUploadStatus = [FileStatus.uploading]
const isRunningStatus = [...isRunningCompressStatus, ...isRunningUploadStatus]

const compressLimit = 10
const limitUpload = 5

const useUploader = (galleryId: string) => {
  const auth = useAuth()
  const queueRef = useRef<{[key: string]: FileInQueue}>({})

  const addToQueue = (files: File[]) => {
    for (const file of files) {
      const key = uuidv4()

      queueRef.current[key] = {
        key,
        date: fileToDate(file).toString(),
        status: FileStatus.idle,
        progress: 0,
        name: file.name,
        url: URL.createObjectURL(file),
        size: file.size,
        files: {
          original: file,
          file
        }
      }
    }
  }

  const reset = (key: string) => {
    queueRef.current[key].status = FileStatus.idle
  }


  const [queue, setQueue] = useState<FileInQueueState[]>([])

  const interval = useInterval(() => {
    //Status for frontend
    const newQueue = Object.values(queueRef.current).map(item => {
      return {...item, files: null}
    })

    setQueue(newQueue)

    //Uploading logic
    const waitingCompress = Object.values(queueRef.current).filter(item => item.status === FileStatus.idle)
    const runningCompress = Object.values(queueRef.current).filter(item => isRunningCompressStatus.includes(item.status)).length

    if(waitingCompress.length > 0 && runningCompress < compressLimit) {
      processFile(waitingCompress[0].key)
    }

    const waitingUpload = Object.values(queueRef.current).filter(item => item.status === FileStatus.wainingForUpload)
    const runningUpload = Object.values(queueRef.current).filter(item => isRunningUploadStatus.includes(item.status)).length

    if(waitingUpload.length > 0 && runningUpload < limitUpload) {
      sendToServer(waitingUpload[0].key)
    }
  }, 300)

  const processFile = (fileId: string) => {
    const file = queueRef.current[fileId]

    if(file.files.file.type === "image/heic") {
      queueRef.current[file.key].status = FileStatus.heic

      heic2any({
        blob: file.files.file,
        toType: "image/jpeg",
        quality: 1
      }).then(jpeg => {
        //@ts-ignore
        queueRef.current[fileId].files.file = jpeg
        compressFile(fileId)
      })
    } else {
      compressFile(fileId)
    }
  }

  const compressFile = (fileId: string) => {
    const file = queueRef.current[fileId]

    queueRef.current[file.key].status = FileStatus.compression

    new Compressor(file.files.file, {
      quality: 0.9,
      success(result) {
        const percent = Math.round(100 - (result.size / file.files.original.size * 100))

        queueRef.current[fileId].files.file = result
        queueRef.current[fileId].compression = percent
        queueRef.current[fileId].compressionSize = result.size

        prepareToSend(fileId)
      },
      error(error) {
        prepareToSend(fileId)
      }
    })
  }

  const prepareToSend = (fileId: string) => {
    const file = queueRef.current[fileId]
    queueRef.current[file.key].status = FileStatus.wainingForUpload
  }

  const sendToServer = (fileId: string) => {
    const file = queueRef.current[fileId]

    queueRef.current[file.key].status = FileStatus.uploading
    queueRef.current[file.key].progress = 0

    const formData = new FormData()
    const attachement = new File([file.files.file], file.name)
    formData.append("file", attachement);
    formData.append("lastModified", fileToDate(file.files.original).toString());

    axios.post(`${config.apiURL}/api/galleries/${galleryId}/upload`, formData, {
      onUploadProgress: (progressEvent => {
        // @ts-ignore
        const progress = Math.round(progressEvent.loaded / progressEvent.total * 100)
        queueRef.current[file.key].progress = progress
      }),
     headers: {'Authorization': `Bearer ${auth.apiKey}`}
    }).then(() => {
      queueRef.current[file.key].status = FileStatus.done
      queueRef.current[file.key].progress = 100
    }).catch(() => {
      queueRef.current[file.key].status = FileStatus.failed
    })
  }

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  return {
    queue,
    addToQueue,
    reset,
    stats: {
      waiting: queue.filter(item => item.status === FileStatus.idle).length,
      processing: queue.filter(item => isRunningStatus.includes(item.status)).length,
      failed: queue.filter(item => item.status === FileStatus.failed).length,
      done: queue.filter(item => item.status === FileStatus.done).length,
      total: queue.length
    }
  }
}

export default useUploader
