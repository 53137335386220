import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";
import useAuth from "../../../../utils/auth";

const useCreateGallery = () => {
  const client = useQueryClient()
  const auth = useAuth()

  return useMutation({
    mutationFn: (data: any) => {
      return axios.post(`${config.apiURL}/api/galleries/new`, data, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
    },
    onSuccess: (res, variables) => {
      const year = new Date(variables.date).getFullYear()

      client.invalidateQueries({
        queryKey: ['galleries', year],
      })
    }
  })

}

export default useCreateGallery
