import {useEffect, useState} from "react";

// @ts-ignore
import html2pdf from "html-to-pdf-js"

import styles from "./PDFView.module.css"
import {Button} from "@mantine/core";
import useAuth from "../../../../utils/auth";
import html2canvas from "html2canvas";
import useMDebounce from "../../../../utils/useDebounce";

interface Props {
  item: any
}

const titleSizes = {
  "sm": "2rem",
  "md": "2.5rem",
  "lg": "3rem",
  "xl": "4rem",
  "xxl": "4.5rem",
  "mega": "5rem",
  "extra": "7rem",
}

const PDFView = ({item, ...props}: Props) => {
  const auth = useAuth()

  const [src, setSrc] = useState("")
  const [imgSrc, setImgSrc] = useState("")

  const genPreview = useMDebounce((item: any) => {
    const element = document.getElementById('element-to-print');

    html2pdf().set({
      html2canvas: {scale: 5},
    }).from(element).toPdf().output('blob').then((data: any) => {
      setSrc(URL.createObjectURL(data))
    })

    html2pdf().set({
      html2canvas: {scale: 5},
      image: { type: 'jpeg', quality: 0.98 },
    }).from(element).then((data: any) => {
      setImgSrc(URL.createObjectURL(data))
    })
  }, 3000)


  useEffect(() => {
    genPreview(item)
  }, [item])


  const downloadPDF = () => {
    window.open(src);
  }

  const downloadIMG = () => {
    const element = document.getElementById('element-to-print');

    // @ts-ignore
    element.parentElement.style.display = "block"

    // @ts-ignore
    html2canvas(element, {}).then(canvas => {
      canvas.toBlob((blob) => {
        if(blob)window.open(URL.createObjectURL(blob));
      })


      // @ts-ignore
      element.parentElement.style.display = "none"
    })
  }

  return (
    <div>
      <iframe src={src} className={styles.iframeView} frameBorder="0"></iframe>

      <Button onClick={downloadPDF} mb="md">Download pdf</Button>
      <Button onClick={downloadIMG} mb="md" ml="md">Download img</Button>

      <div className={styles.wrapper} style={{display: "none"}}>
        <div id="element-to-print" className={styles.renderer}
             style={{position: "relative", backgroundImage: `url("/img/generator/bg/${item.bg}.jpg")`}}>

          <div className={styles.illustration} style={{backgroundImage: `url("/img/generator/illustraion/${item.images}.png")`}}></div>

          <div style={{
            position: "absolute",
            top: "0",
            left: "0"
          }}>
            <img src="/img/generator/Logo5-barevne-transparent.png" alt="" style={{height: "33mm"}}/>
          </div>

          <div className={styles.container}>
            <div style={{
              //@ts-ignore
              fontSize: titleSizes[item.nameSize],
              color: item.nameColor,
            }}

                 className={styles.rendererTitle}
                 dangerouslySetInnerHTML={{__html: item.name}}></div>


            <div style={{}}
                 className={styles.rendererText}
                 dangerouslySetInnerHTML={{__html: item.text}}></div>
          </div>

          <div style={{position: "absolute", bottom: "10px", right: "10px", color: "#afafaf", fontSize: "8px"}}>PWA-GEN | {auth.user?.name}</div>
        </div>
      </div>
    </div>
  )
}

export default PDFView
