import {Dropzone, FileWithPath, IMAGE_MIME_TYPE} from "@mantine/dropzone";
import {Badge, Box, Button, Group, Image, Progress, rem, Table, Text} from "@mantine/core";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons-react";
import React from "react";
import useUploader, {FileStatus} from "./useUploader";
import {timeToString} from "../../../../utils/dateToString";

interface Props {
  galleryID: string
}

function getReadableFileSizeString(fileSizeInBytes: number) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

const Upload = (props: Props) => {
  const uploader = useUploader(props.galleryID)
  const handleDrop = (files: FileWithPath[]) => {
    uploader.addToQueue(files)
  }

  return (
    <>
      {
        uploader.stats.total === 0 ? (
          <Dropzone
            onDrop={handleDrop}
            onReject={(files) => console.log('rejected files', files)}
            accept={IMAGE_MIME_TYPE}
            mt="md"
          >
            <Group justify="center" gap="xl" mih={220} style={{pointerEvents: 'none'}}>
              <Dropzone.Accept>
                <IconUpload
                  style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)'}}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)'}}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto
                  style={{width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)'}}
                  stroke={1.5}
                />
              </Dropzone.Idle>

              <div>
                <Text size="xl" inline>
                  Přetáhni obrázky sem nebo klikni pro výběr souborů
                </Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                  Vyber kolik fotek chceě - celkový limit není omezen
                </Text>
              </div>
            </Group>
          </Dropzone>
        ) : null
      }

      <Progress.Root size="xxl" h={40} mt="xs">
        <Progress.Section value={((uploader.stats.waiting / uploader.stats.total) || 0) * 100} color="gray">
          <Progress.Label>{uploader.stats.waiting} Čeká ve frontě</Progress.Label>
        </Progress.Section>
        <Progress.Section value={((uploader.stats.processing / uploader.stats.total) || 0) * 100} color="orange">
          <Progress.Label>{uploader.stats.processing} Nahrávání</Progress.Label>
        </Progress.Section>
        <Progress.Section value={((uploader.stats.done / uploader.stats.total) || 0)  * 100} color="green" striped animated>
          <Progress.Label>{uploader.stats.done} Nahráno</Progress.Label>
        </Progress.Section>
        <Progress.Section value={((uploader.stats.failed / uploader.stats.total) || 0) * 100} color="red" striped animated>
          <Progress.Label>{uploader.stats.failed} Chyb</Progress.Label>
        </Progress.Section>
      </Progress.Root>

      <Table striped mt="md">
        <Table.Tbody>
          {
            uploader.queue.map((file, index) => (
              <Table.Tr key={index}>
                <Table.Td>
                  <Group>
                    <Image fit="contain" width={100} height={50} src={file.url}/>
                    <Box>
                      <Text>{file.name}</Text>
                      <Text size="xs">{file.date} {timeToString(file.date)}</Text>
                      <Badge>Komprese {file.compression}% {getReadableFileSizeString(file.size)} - {file.compressionSize ? getReadableFileSizeString(file.compressionSize) : ""}</Badge>
                      {
                        file.status === FileStatus.done ? (
                          <Badge color="green" ml="xs">Nahráno</Badge>
                        ) : null
                      }
                    </Box>
                  </Group>

                  {
                    file.status === FileStatus.idle ? (
                      <Progress.Root size="xxl" h={20} mt="xs">
                        <Progress.Section value={100} color="gray" striped animated>
                          <Progress.Label>Čeká v řadě</Progress.Label>
                        </Progress.Section>
                      </Progress.Root>
                    ) : file.status === FileStatus.heic ? (
                      <Progress.Root size="xxl" h={20} mt="xs">
                        <Progress.Section value={100} color="gray" striped animated>
                          <Progress.Label>Převod z HEIC do jpeg (iphone formát)</Progress.Label>
                        </Progress.Section>
                      </Progress.Root>
                    ) : file.status === FileStatus.compression ? (
                      <Progress.Root size="xxl" h={20} mt="md">
                        <Progress.Section value={100} color="orange" striped animated>
                          <Progress.Label>komprese</Progress.Label>
                        </Progress.Section>
                      </Progress.Root>
                    ) : file.status === FileStatus.wainingForUpload ? (
                      <Progress.Root size="xxl" h={20} mt="md">
                        <Progress.Section value={100} color="orange" striped>
                          <Progress.Label>Zkompresováno, čeká na nahrání</Progress.Label>
                        </Progress.Section>
                      </Progress.Root>
                    ) : file.status === FileStatus.failed ? (
                      <>
                        <Progress.Root size="xxl" h={20} mt="md">
                          <Progress.Section value={100} color="red">
                            <Progress.Label>Nahrávání selhalo</Progress.Label>
                          </Progress.Section>
                        </Progress.Root>
                        <Button fullWidth onClick={() => uploader.reset(file.key)} size="xs" mt="md">Zkusit znovu</Button>
                      </>
                    ) : file.status === FileStatus.done ? (
                      ""
                    ) : (
                      <>
                        <Progress.Root size="xxl" h={20} mt="xs">
                          <Progress.Section value={file.progress} striped animated>
                            <Progress.Label>{file.progress}% nahrávání</Progress.Label>
                          </Progress.Section>
                        </Progress.Root>
                      </>
                    )
                  }
                </Table.Td>
              </Table.Tr>
            ))
          }
        </Table.Tbody>
      </Table>
    </>
  )
}

export default Upload
