import React, { useRef, useEffect, MutableRefObject } from "react"

import { isValidDate, getInputDateTimeFormat } from "./date"

interface Props {
  submitId?: string
  children: React.ReactNode
  onChange?: (values: object) => void
  onSubmit?: (values: object) => void
  formRef?: MutableRefObject<HTMLFormElement>
  defaultValues?: { [key: string]: string } | object
}

const Form = ({ children, ...props }: Props) => {
  // @ts-ignore
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formRef = props.formRef || useRef<HTMLFormElement>()

  const onSubmit = (e: React.FormEvent) => {
    e.stopPropagation()
    e.preventDefault()

    //@ts-ignore
    if (props.submitId && props.submitId !== e.nativeEvent.submitter.id) return null

    e.stopPropagation()
    e.preventDefault()
    if (props.onSubmit) props.onSubmit(getValues())
  }

  const getElements = () => {

    return [
      // @ts-ignore
      ...Array.from(formRef.current.getElementsByTagName("input")),
      // @ts-ignore
      ...Array.from(formRef.current.getElementsByTagName("textarea")),
      // @ts-ignore
      ...Array.from(formRef.current.getElementsByTagName("select"))
    ]
  }

  const getValues = () => {
    const elements = getElements()
    const values = {}

    for (const el of elements) {
      if (el.type === "date" || el.type === "datetime-local") {
        const date = new Date(el.value)
        // @ts-ignore
        values[el.name] = isValidDate(date) ? date : null
      } else if (el.type === "checkbox" && "checked" in el) {
        // @ts-ignore
        values[el.name] = el.checked
      } else {
        // @ts-ignore
        values[el.name] = el.value
      }
    }

    return values
  }

  const onChange = () => {
    if (props.onChange) props.onChange(getValues())
  }

  const onReset = () => {
    const inputs = getElements()

    for (const input of inputs) {
      if (input.type === "checkbox") {
        //@ts-ignore
        input.checked = input.defaultChecked
      } else {
        //@ts-ignore
        input.value = input.defaultValue
      }

      //@ts-ignore
      if (input.onchange) input.onchange({ target: input })
    }
  }

  useEffect(() => {
    const elements = getElements()

    for (const el of elements) {
      el.addEventListener("change", onChange)

      // @ts-ignore
      if (props.defaultValues && props.defaultValues[el.name]) {
        if (el.type === "date" || el.type === "datetime-local") {
          // @ts-ignore
          const date = new Date(props.defaultValues[el.name])
          el.value = getInputDateTimeFormat(date)
        } else {
          // @ts-ignore
          el.value = props.defaultValues[el.name]
        }
      }
    }

    return () => {
      for (const el of elements) {
        el.removeEventListener("change", onChange)
      }
    }
  }, [])

  return (
    // @ts-ignore
    <form ref={formRef} onReset={onReset} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default Form
