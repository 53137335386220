import {UiText} from "./types/config";
import {AppRow} from "./hooks/useData";

export const getText = (template: UiText[] | null, data: AppRow) => {
  if (!template) return ""

  let string = []

  for (const pattern of template) {
    switch (pattern.type) {
      case "col":
        string.push(data.data[pattern.key])
        break
      case "string":
        string.push(pattern.value)
        break
    }
  }

  return string.join(" ")
}

export const isDone = (config: string, data: AppRow) => {
  let evalString = config

  for (const key of Object.keys(data.data)) {
    evalString = evalString.replaceAll(`[${key}]`, data.data[key])

  }

  // eslint-disable-next-line no-eval
  return !!(eval(evalString))
}

export const getAllInputs = (element: HTMLDivElement) => {
  const data: {[key: string]: string|number|boolean} = {}

  for (const input of Array.from(element.getElementsByTagName("input"))) {
    if(input.inputMode === "numeric") {
      data[input.name] = parseFloat(input.value)
      continue
    }

    switch (input.type) {
      case "number":
        data[input.name] = parseFloat(input.value)
        break
      case "checkbox":
        data[input.name] = input.checked
        break
      default:
        data[input.name] = input.value
    }
  }

  for (const input of Array.from(element.getElementsByTagName("textarea"))) {
    data[input.name] = input.value
  }

  for (const input of Array.from(element.getElementsByTagName("select"))) {
    data[input.name] = input.value
  }

  return data
}
