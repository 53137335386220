import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Auth from "./components/Pages/Auth";
import Dashboard from "./components/Pages/Dashboard";
import EnsureLogin from "./wrapper/EnsureLogin";
import AuthVerify from "./components/Pages/AuthVerify";
import GalleryList from "./components/Pages/Galeries/list";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import GalleryDetail from "./components/Pages/Galeries/Detail";
import config from "./config";
import EventsPostersGeneratorPage from "./components/Pages/Generator/List";
import EditPage from "./components/Pages/Generator/Edit";
import GalleryUpload from "./components/Pages/Galeries/Upload";
import DownloadApp from "./components/Pages/Auth/DownloadApp";
import AppList from "./components/Pages/GoogleSheets/AppList";
import NotFoundPage from "./components/Pages/Error/NotFoundPage";
import RowDetail from "./components/Pages/GoogleSheets/RowDetail";

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/auth/download-app" element={<DownloadApp/>}/>
            <Route path="/auth/token" element={<AuthVerify/>}/>
            <Route path="/auth" element={<Auth/>}/>
            <Route path="/galeries" element={<EnsureLogin><GalleryList/></EnsureLogin>}/>
            <Route path="/galeries/:id" element={<EnsureLogin><GalleryDetail/></EnsureLogin>}/>
            <Route path="/galeries/:id/upload" element={<EnsureLogin><GalleryUpload/></EnsureLogin>}/>
            <Route path="/generator/events" element={<EnsureLogin><EventsPostersGeneratorPage/></EnsureLogin>}/>
            <Route path="/generator/events/:id" element={<EnsureLogin><EditPage/></EnsureLogin>}/>
            <Route path="/drive-apps/:id" element={<EnsureLogin><AppList/></EnsureLogin>}/>
            <Route path="/drive-apps/:id/:row" element={<EnsureLogin><RowDetail/></EnsureLogin>}/>
            <Route path="/" element={<EnsureLogin><Dashboard/></EnsureLogin>}/>
            <Route path="*" element={<NotFoundPage/>}/>
          </Routes>
        </BrowserRouter>

        {
          config.showDebug ? <ReactQueryDevtools/> : null
        }
      </QueryClientProvider>
    </div>
  );
}

export default App;
