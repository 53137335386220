import {Box, Group, Loader, Text} from "@mantine/core";
import {IconArmchair2Off, IconMoodConfuzed} from "@tabler/icons-react";
import React from "react";

const EmptyState = ({isEmpty, isLoading, is404}: { isEmpty: boolean, isLoading: boolean, is404?: boolean }) => {
  if (!isEmpty) return null

  if (isLoading) {
    return (
      <Group justify="center" gap="sm" mt="xl">
        <Loader color="lime" size="lg" type="dots"/>
      </Group>
    )
  }

  if(is404) {
    return (
      <Group justify="center" align="center" mt="xl">
        <IconArmchair2Off size={80} color="gray"/>
        <Box>
          <Text fw="bold" size="xl">Hledaná položka neexistuje</Text>
          <Text>Něco se asi nepovedlo.</Text>
        </Box>
      </Group>
    )
  }

  return (
    <Group justify="center" align="center" mt="xl">
      <IconMoodConfuzed size={80} color="gray"/>
      <Box>
        <Text fw="bold" size="xl">Je tu zatím prázdno</Text>
        <Text>Přidej první položku.</Text>
      </Box>
    </Group>
  )
}

export default EmptyState
