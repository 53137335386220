const f2 = (n: number) => (n < 10 ? `0${n}` : n)

export const getInputDateTime = (date: number | string) => {
  const d = new Date(date)

  return getInputDateTimeFormat(d)
}

export const getInputDateTimeFormat = (d: Date) => {
  if (!isValidDate(d)) return ""

  return `${d.getFullYear()}-${f2(d.getMonth() + 1)}-${f2(d.getDate())}T${f2(d.getHours())}:${f2(d.getMinutes())}`
}

export const getInputDateFormat = (d: Date) => {
  if (!isValidDate(d)) return ""

  return `${d.getFullYear()}-${f2(d.getMonth() + 1)}-${f2(d.getDate())}`
}

export const getReadDateTime = (date: number | string) => {
  const d = new Date(date)

  if (!isValidDate(d)) return "--"

  return `${d.getFullYear()}-${f2(d.getMonth() + 1)}-${f2(d.getDate())} ${f2(d.getHours())}:${f2(d.getMinutes())}`
}

export const getPHPDate = (date: number | string) => {
  const d = new Date(date)

  if (!isValidDate(d)) return "--"

  return `${d.getFullYear()}-${f2(d.getMonth() + 1)}-${f2(d.getDate())}`
}

export const isValidDate = (d: any) => {
  // @ts-ignore
  return d instanceof Date && !isNaN(d)
}
