import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";
import useAuth from "../../../../utils/auth";

const useUpdateGallery = () => {
  const auth = useAuth()
  const client = useQueryClient()

  return useMutation({
    mutationFn: (data: { id: number, data: any }) => {
      return axios.put(`${config.apiURL}/api/galleries/id/${data.id}`, data.data, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
    },
    onSuccess: (res, variables) => {
      const year = new Date(variables.data.date).getFullYear()

      client.invalidateQueries({
        queryKey: ['galleries', year],
      })

      client.invalidateQueries({
        queryKey: ['galleries-id', variables.id],
      })
    }
  })

}

export default useUpdateGallery
