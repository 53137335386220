import {HeaderTabs} from "../../../Layout/HeaderTabs";
import {
  Alert,
  Anchor, Badge, Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Flex,
  Group,
  Image,
  Loader, rem,
  ScrollArea,
  SimpleGrid, Text, Title
} from "@mantine/core";
import {Link, useParams} from "react-router-dom";
import React from "react";
import useGalleries from "../hooks/useGalleries";
import GalleryCard from "../list/GalleryCard";
import useGalleryPhotos from "../hooks/useGalleryPhotos";
import PhotoEl from "./Photo";
import {IconInfoCircle} from "@tabler/icons-react";
import CreateModal from "../Create/CreateModal";
import Upload from "../Upload/Upload";
import useGallery from "../hooks/useGallery";
import EmptyState from "../../../Shared/EmptyState";
import config from "../../../../config";
import PhotoAlbumView from "./PhotoAlbumView";

const GalleryDetail = () => {
  const params = useParams()
  const id = params.id ? parseInt(params.id) : null

  const {gallery} = useGallery(id)
  const {gallery: upGallery} = useGallery(gallery?.upper_gallery ?? null)

  const photos = useGalleryPhotos(id)

  return (
    <>
      <HeaderTabs/>
      <Container size="md" mb={20}>
        <Breadcrumbs mb={10}>
          <Link to="/"><Anchor>Dashboard</Anchor></Link>
          <Link to="/galeries"><Anchor>Galerie</Anchor></Link>
          {upGallery ? <Link
            to={`/galeries/${upGallery?.id}`}><Anchor>{upGallery?.title} ({upGallery?.date})</Anchor></Link> : null}
          <Link to={`/galeries/${gallery?.id}`}><Anchor>{gallery?.title} ({gallery?.date})</Anchor></Link>
        </Breadcrumbs>

        <Flex justify="space-between" align="flex-start" mt="md" >
          <Box>
            <Title order={1} size="1.5rem" fw="bold">{gallery?.title ?? "Galerie"}</Title>
            <Text>{gallery?.date}</Text>
            {
              gallery?.hide_date ? (<Badge color="red">Schovat datum</Badge>) : null
            }
            {
              gallery?.hide_from_users ? (<Badge color="red">ADMIN ONLY</Badge>) : null
            }
          </Box>
          <Link to={"upload"}>
            <Button mb="md" color="green">+ Nahrát fotky</Button>
          </Link>
        </Flex>

        {
          photos.isError ? (
            <Alert variant="light" color="red" title="Chyba" icon={<IconInfoCircle/>}>
              Nepodařilo se načíst data
            </Alert>
          ) : null
        }

        <ScrollArea>
          <Flex mt={20} gap={10}>
            {
              gallery?.sub_galleries?.map((gallery, index) => (
                  <GalleryCard gallery={gallery} key={gallery.id}/>
                )
              )
            }
          </Flex>
        </ScrollArea>


        {/*
        <SimpleGrid
          cols={{base: 2, sm: 3, lg: 4}}
          mt={20}
        >
          {
            photos.data?.photos?.map(photo => (
              <PhotoEl slug={photos?.data?.slug} photo={photo} key={photo.id} galleryId={id}/>
            ))
          }
        </SimpleGrid>
        */}

        <div>
          <PhotoAlbumView galleryId={id} slug={photos?.data?.slug || ""} photos={photos.data?.photos || []}/>
        </div>

        {
          photos.isFetching ? (
            <Group justify="center" gap="sm" mt="xl">
              <Loader color="lime" size="lg" type="dots"/>
            </Group>
          ) : null
        }

        <EmptyState isEmpty={!photos?.data?.photos?.length} isLoading={photos.isLoading}/>

        <CreateModal upperId={id} gallery={gallery}/>
      </Container>


    </>
  )
}

export default GalleryDetail
