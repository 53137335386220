import {useDisclosure} from "@mantine/hooks";
import {Modal, Box, ActionIcon, Flex, Alert} from "@mantine/core";
import React, {useState} from "react";
import {getPHPDate} from "../../../../utils/date";
import {useNavigate} from "react-router-dom";
import {IconEdit, IconInfoCircle, IconPlus} from "@tabler/icons-react";
import useCreateGallery from "../hooks/useCreateGallery";
import GalleryForm from "../Components/GalleryForm";
import {Gallery} from "../hooks/useGalleries";
import useUpdateGallery from "../hooks/useUpdateGallery";
import processAxiosError from "../../../../utils/processAxiosError";

interface Props {
  gallery?: Gallery
  upperId?: number | null
}

const CreateModal = ({upperId, ...props}: Props) => {
  const [opened, {open, close}] = useDisclosure(false);
  const [edit, setEdit] = useState(false)

  const navigate = useNavigate()

  const createGallery = useCreateGallery()
  const updateGallery = useUpdateGallery()

  const openEdit = () => {
    setEdit(true)
    open()
  }

  const openNew = () => {
    setEdit(false)
    open()
  }

  const handleSubmit = (values: any) => {
    if(props.gallery && edit) {
      updateGallery.mutateAsync({
        id: props.gallery.id, data: values
      }).then((res) => {
        close()
      })
    } else {
      createGallery.mutateAsync(values).then((res) => {
        close()
        navigate(`/galeries/${res.data.id}`)
      })
    }
  }

  const error = processAxiosError(edit ? updateGallery?.error : createGallery?.error)

  return (
    <>
      <Modal opened={opened} onClose={close} title={edit ? "Upravit galerii" : "Vytvořit novou galerii"} closeOnClickOutside={false} size="xl">
        {
          error ? (
            <Alert variant="light" color="red" title={error?.title} icon={<IconInfoCircle />} mb="xl">{error?.text} </Alert>
          ) : null
        }

        {
          opened ? (
            <GalleryForm loading={createGallery.isPending || updateGallery.isPending} handleSubmit={handleSubmit} upperId={upperId} gallery={edit ? props.gallery : undefined}/>
          ) : null
        }
      </Modal>

      {
        !opened ? (
          <Flex direction="column" gap="1rem" style={{position: "fixed", bottom: "2rem", right: "2rem", zIndex: 9999}}>
            <ActionIcon variant="filled" color="green" size="xl" radius="xl" onClick={openNew}>
              <IconPlus style={{width: '70%', height: '70%'}} stroke={1.5}/>
            </ActionIcon>
            {
              props.gallery ? (
                <ActionIcon variant="filled" color="blue" size="xl" radius="xl" onClick={openEdit}>
                  <IconEdit style={{width: '70%', height: '70%'}} stroke={1.5}/>
                </ActionIcon>
              ) : null
            }
          </Flex>
        ) : null
      }
    </>
  )
}

export default CreateModal
