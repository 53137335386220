import {HeaderTabs} from "../../Layout/HeaderTabs";
import { Box, Container, Group, Text, Title} from "@mantine/core";
import FeatureCard from "./FeatureCard";
import React from "react";
import useAuth from "../../../utils/auth";
import osloveni from "../../../utils/osloveni";

const Dashboard = () => {
  const auth = useAuth()

  const name = osloveni(auth.user?.name.split(" ")[0] || "")

  return (
    <>
      <HeaderTabs/>

      <Container size="md">

        <Box mb="xl">
          <Title size="3rem" c="#111f47" order={1} ff="skautbold">Nazdar!</Title>
          <Text size="2rem"  c="#111f47"ff="themix" fw="bold">
            Je na čase přidat fotky {name}?
          </Text>
        </Box>

        <Group>
          <FeatureCard color="red" btnColor="#e62c2c" title="Fotky" desc="Správa galerií a fotek" href="/galeries" /> {/*    */}
          <FeatureCard  color="blue" btnColor="#1770bd" title="Plakátky" desc="Generování plakátků na akce" href="/generator/events"/>
          <FeatureCard  color="green" btnColor="#1F8E32" title="Tábor 2024" desc="Kontakty na rodiče" href="/drive-apps/tabor-2024"/>
        </Group>
      </Container>
    </>
  )
}

export default Dashboard
