// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_logo__nmFdR {
    text-align: center;

    img {
        height: 4em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Auth.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB;QACI,WAAW;IACf;AACJ","sourcesContent":[".logo {\n    text-align: center;\n\n    img {\n        height: 4em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Auth_logo__nmFdR`
};
export default ___CSS_LOADER_EXPORT___;
