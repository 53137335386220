import useAuth from "../../../../utils/auth";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";

export interface AppRow {
  "row_number": number,
  "row_range": string
  "data": {[key: string]: string}
}

const useAppList = (id: string) => {
  const auth = useAuth()

  return useQuery({
    queryKey: ['apps-data', id],
    enabled: !!id,
    queryFn: () =>
      axios
        .get<AppRow[]>(`${config.gDriveApi}/apps/${id}/list`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })
}

export default useAppList
