import useLocalStorage from "use-local-storage";
import {useState} from "react";

export interface User {
  name: string
}

const useAuth = () => {
  const [apiKey, setApiKey] = useLocalStorage<string|null>("api-key", "")
  const [apiUser, setApiUser] = useLocalStorage<User|null>("api-user", null)
  const [verified, setVerified] = useState(false)

  const login = (key: string, user: User) => {
    setApiKey(key)
    setApiUser(user)
    setVerified(true)
  }

  const logout = () => {
    setApiKey("")
    setApiUser(null)
  }

  return {
    login,
    logout,
    user: apiUser,
    apiKey,
    verified
  }
}

export default useAuth
