import {Box, Flex, Text, Title} from "@mantine/core";
import React from "react";

interface Props {
  title: string
  subTitle?: string
  btn?: React.ReactNode
}

const PageHeader = (props: Props) => {
  return (
    <Flex justify="space-between" align="flex-start" mt="md" mb="xl">
      <Box>
        <Title order={1} size="1.5rem" fw="bold">{props.title}</Title>
        <Text>{props.subTitle}</Text>
      </Box>
      {props.btn}
    </Flex>
  )
}

export default PageHeader
