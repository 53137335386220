// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PDFView_iframeView__Z\\+TDH {
    aspect-ratio: 210 / 297;
    width: 100%;
}

.PDFView_wrapper__txtOP, .PDFView_renderer__JuDeN {
    width: 210mm;
    height: 296.5mm;
}

.PDFView_wrapper__txtOP {
    border: 1px solid #e0e0e0;
    margin-bottom: 5rem;
}

.PDFView_renderer__JuDeN {
    background-position: center;
    background-size: cover;
    background-color: white;
}

.PDFView_renderer__JuDeN * {

}

.PDFView_container__swJU7 {
    position: absolute;
    top: 8.5rem;
    left: 10%;
    width: 80%;
}

.PDFView_rendererTitle__qDfCI {
    text-align: center;
    font-family: "skautbold", serif;
    line-height: 1.2;
    line-break: anywhere;
}

.PDFView_rendererTitle__qDfCI p {
    margin: 0;
}


.PDFView_rendererText__1w8RV {
    margin-top: 3rem;
    font-family: "themix", serif;
}


.PDFView_rendererText__1w8RV p {
    margin: 0;
    min-height: 1.2rem;
}

.PDFView_illustration__fbK4Y {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Generator/Edit/PDFView.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;;AAEA;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,SAAS;AACb;;;AAGA;IACI,gBAAgB;IAChB,4BAA4B;AAChC;;;AAGA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":[".iframeView {\n    aspect-ratio: 210 / 297;\n    width: 100%;\n}\n\n.wrapper, .renderer {\n    width: 210mm;\n    height: 296.5mm;\n}\n\n.wrapper {\n    border: 1px solid #e0e0e0;\n    margin-bottom: 5rem;\n}\n\n.renderer {\n    background-position: center;\n    background-size: cover;\n    background-color: white;\n}\n\n.renderer * {\n\n}\n\n.container {\n    position: absolute;\n    top: 8.5rem;\n    left: 10%;\n    width: 80%;\n}\n\n.rendererTitle {\n    text-align: center;\n    font-family: \"skautbold\", serif;\n    line-height: 1.2;\n    line-break: anywhere;\n}\n\n.rendererTitle p {\n    margin: 0;\n}\n\n\n.rendererText {\n    margin-top: 3rem;\n    font-family: \"themix\", serif;\n}\n\n\n.rendererText p {\n    margin: 0;\n    min-height: 1.2rem;\n}\n\n.illustration {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-position: center;\n    background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframeView": `PDFView_iframeView__Z+TDH`,
	"wrapper": `PDFView_wrapper__txtOP`,
	"renderer": `PDFView_renderer__JuDeN`,
	"container": `PDFView_container__swJU7`,
	"rendererTitle": `PDFView_rendererTitle__qDfCI`,
	"rendererText": `PDFView_rendererText__1w8RV`,
	"illustration": `PDFView_illustration__fbK4Y`
};
export default ___CSS_LOADER_EXPORT___;
