import {useSearchParams} from "react-router-dom";
import {useMemo} from "react";

const useSearchParamKey = (key: string) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const value = useMemo(() => {
    return searchParams.get(key)
  }, [searchParams])


  return value
}

export default useSearchParamKey
