import useAuth from "../../../../utils/auth";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";

export interface AppRow {
  "row_number": number,
  "row_range": string
  "data": {[key: string]: string}
}

const useAppDataRow = (id: string, row: string) => {
  const auth = useAuth()

  return useQuery({
    queryKey: ['apps-data-row', id, row],
    enabled: !!id && !!row,
    queryFn: () =>
      axios
        .get<AppRow>(`${config.gDriveApi}/apps/${id}/row/${row}`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })
}

export default useAppDataRow
