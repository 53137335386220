import useAuth from "../../../../utils/auth";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";

export interface Photo {
  "id": number,
  "gallery_id": string|number
  "path": string
  "upload": string
  "name": string
  "created": string
  "locked": number|boolean,
  "sort_time": string
  width?: number
  height?: number
}

const useGalleryPhotos = (id: number|null) => {
  const auth = useAuth()

  const photos = useQuery({
    queryKey: ['gallery', id],
    queryFn: () =>
      axios
        .get<{slug: string, photos: Photo[]}>(`${config.apiURL}/api/galleries/id/${id}/photos`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })

  return {
    ...photos
  }
}

export default useGalleryPhotos
